// TODO: Catch?
export const asyncEvery = async (
  promises: Promise<boolean>[],
): Promise<boolean> => {
  return (await Promise.all(promises)).every((result) => result);
};

// TODO: catch?
export const asyncSome = async (
  promises: Promise<boolean>[],
): Promise<boolean> => {
  return (await Promise.all(promises)).some((result) => result);
};

// async counterpart to Array.prototype.find
export async function asyncFind<T>(
  arr: T[],
  cb: (el: T) => Promise<boolean>,
): Promise<T | undefined> {
  for (const el of arr) {
    if (await cb(el)) {
      return el;
    }
  }
}

export function sleep(delay: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

/**
 * Used for converting a synchronous `for` loop into an async `for` loop that batches
 * its iterations to prevent the loop from hogging the javascript event loop.
 *
 * @param elements The list to iterate over.
 * @param desiredWait The desired interval in millis in which the loop should delegate back to the event loop to continue.
 * @param callback The function to call over each element in the list.
 * @param sleepTime How long we sleep after each yield
 */
export async function yieldingLoop<T>(
  elements: T[],
  desiredWait: number,
  callback: (element: T) => void,
  sleepTime = 0,
): Promise<void> {
  let lastPause = Date.now();
  for (const element of elements) {
    if (Date.now() - lastPause > desiredWait) {
      await sleep(sleepTime);
      lastPause = Date.now();
    }
    callback(element);
  }
}

/**
 * Calls @param callback periodically (every @param periodMs milliseconds)
 * until @param promise is settled.
 */
export async function periodicallyWhile<T>(
  promise: Promise<T>,
  callback: () => void,
  periodMs: number,
): Promise<T> {
  let settled = false;

  function check(): void {
    if (!settled) {
      callback();
      setTimeout(check, periodMs);
    }
  }
  setTimeout(check, periodMs);

  try {
    return await promise;
  } finally {
    settled = true;
  }
}
